<template>
  <!-- <div>
    <Header/>
  </div> -->

  <div v-if="authStatus == 'authenticated'">
    <!-- <MenuLateral/> -->
    <MenuHorizontal/>
  </div>


  <div class="router-view">
    <router-view/>
  </div>

  <div>
    <Footer/>
  </div>

  
</template>

<script>
  import Header from '.././components/Header.vue'
  import Footer from '.././components/Footer.vue';
  import MenuLateral from '.././components/MenuLateral.vue'
  import useAuth from '.././composable/useAuth';
  import MenuHorizontal from '../components/MenuHorizontal.vue';

  export default{
    setup() {
        const { authStatus, checkAutStatus } = useAuth();
        checkAutStatus();
        return {
            authStatus
        };
    },
    components() {
        return {
            Header,
            MenuLateral,
            Footer
        };
    },
    components: { MenuLateral, Header, Footer, MenuHorizontal }
}

  
</script>


<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.logo{
  width: 3em;
  margin-bottom: 1em;
  margin-top: 1em;
}

.container{
  width: 100%;
}

.container-login{
  margin-left: 0;
}

html, body{
  padding: 0;
  margin: 0;
}

.router-view{
  margin-right: 2em;
  margin-left: 2em;
  padding: 1em;
  padding-top: 0em;
}

.footer_container {
  position: absolute;
  bottom: 0;
  width: 100%;
}

</style>
