<template>
  <Toast/>
  <Dialog header="Create Account" v-model:visible="showModal" modal class="w-3">
    <UpsertAccountView @closeModal="closeModal" />
  </Dialog>

   <div class="card">
    
    <div class="flex align-content-between">
            <div class="flex flex-1 justify-content-center">
                <h2>Accounts</h2> 
            </div>
            
            <div class="mt-3">
                <Button 
                    label="Add Account" 
                    raised 
                    @click="() => showModal=true" />
            </div>
    </div>

    <DataTable 
      :value="accounts"
      responsiveLayout="scroll" 
      :rows="10"
      :rowsPerPageOptions="[5, 10, 20, 50]"
      :paginator="true"  
      filterDisplay="menu" 
      class="p-datatable-sm">

      <Column field="name" header="Name" :sortable="true" />    
      <Column field="description" header="Description" :sortable="true" />
      <Column field="balance" header="Balance" :sortable="true" >
        <template #body="account">
            {{convert(account.data.balance)}}
        </template>
      </Column>
      <Column field="currency.name" header="Currency" :sortable="true" />
      <Column field="active" header="Status" :sortable="true">
        <template #body="item">
            <span v-if="item.data.active" class="product-badge status-payed" >Active</span>
            <span v-else class="product-badge status-non-payed" >Inactive</span>
        </template>
      </Column>
      <Column field="createdAt" header="Created At" :sortable="true" >
        <template #body="{data}">
                    {{formatDate(data.createdAt)}}
                </template>
      </Column>

    </DataTable>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import useAuth from '@/composable/useAuth';
import cmApi from '../../../api/cmApi';
import UpsertAccountView from './UpsertAccountView.vue';
const cm = require('../../../common/common');

const _useAuth = useAuth();
const userId = ref(_useAuth.userId);
const accounts = ref([]);
const showModal = ref(false);

onMounted(async () => await getAccountsByUser())

const getAccountsByUser = async () => {
  const { data } = await cmApi.get(`/account/getAll/${userId.value}`);
  accounts.value = [];
  accounts.value.push(...data);  
}

const closeModal = async () => {
  await getAccountsByUser();
  showModal.value = false;
}

const convert = (amount, currencyCode = "ARS") => {
    return cm.applyCurrencyFormat(amount, currencyCode)
}

const formatDate = (date) =>{
    return cm.formatLocalDate(date);
}

</script>

<style scoped>
.product-badge{
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;
    }

    .product-badge.status-payed{
        background: #c8e6c9;
        color: #256029;
    }

    .product-badge.status-non-payed {
        background: #ffcdd2;
        color: #c63737;
    }
</style>