
import axios, {InternalAxiosRequestConfig} from "axios";

const baseURL = process.env.VUE_APP_BACK_URL;
const cmApi = axios.create({baseURL});

cmApi.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('token');
        if( token ) config.headers['Authorization'] = `Bearer ${token}`;
        return config;
    }
)

// cmApi.interceptors.response.use((response) => {
//     return response;
// }, (error) => {
//     const useAuth = useAuth();
//     if(error.response.status === 401) useAuth.logout()
//     return Promise.reject(error)
// })

export default cmApi;