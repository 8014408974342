import { createStore } from 'vuex'
import axios from 'axios';
import decodeToken from '../modules/auth/helpers/decodeToken';

export default createStore({
  
  state: {
    status: 'authenticating',
    user: null,
    token: null,
    userName: null,
    userId: null,
    //Not Required
    groupId: null,
    categories: [],
    items: [],

    item:{},

    category: {
      id: '',
      name: '',
      userId: '',
      description: ''
    },

    categorySelected: {},
    flagHomeCateg: false,

    //Se Usa en crear Item
    months : 
    [
      {id: '1', name:'Enero'},
      {id: '2', name:'Febrero'},
      {id: '3', name:'Marzo'},
      {id: '4', name:'Abril'},
      {id: '5', name:'Mayo'},
      {id: '6', name:'Junio'},
      {id: '7', name:'Julio'},
      {id: '8', name:'Agosto'},
      {id: '9', name:'Septiembre'},
      {id: '10', name:'Octubre'},
      {id: '11', name:'Noviembre'},
      {id: '12', name:'Diciembre'}
    ],

    //Se Usa en crear Item
    periods:
    [
      {id: 1, name: 'Mensual'},
      {id: 2, name: 'Bi-mensual'},
      {id: 3, name: 'Trimestral'},
      {id: 6, name: 'Semestral'},
      {id: 12, name: 'Anual'},
      {id: 24, name: 'Bi-Anual'}
    ]
    
  },

  mutations: {
    // load(state, payload){
    //   state.categories = payload
    // },

    // set(state, payload){
    //   state.categories.push(payload);
    // },

    // categorySelected(state, payload){
    //   state.categorySelected = payload;
    // },

    // loadItems(state, payload){
    //   state.items = payload
    // },

    // setItem(state, payload){
    //   state.items.push(payload);
    // }, 

    // setOneitem(state, item){
    //   state.item = item
    // },

    // cleanItems(state){
    //   state.items = [];
    // },

    // setFlagCategoryHome(state, value){
    //   state.flagHomeCateg = value
    // },

    loginUser(state, {access_token}){
      if( access_token ){
        const {uid, user, name} = decodeToken(access_token);
        
        localStorage.setItem('token', access_token);
        localStorage.setItem('user', user);
        localStorage.setItem( 'userName', name);
        localStorage.setItem( 'userId', uid);
        localStorage.setItem( 'groupId', "1");
        state.token = access_token;
        state.user = user;
        state.userName = name;
        state.userId = uid;
        state.groupId = "1";
        state.status = 'authenticated'
      }

     

      

      
      
      // if( data.access_token ){
      //   const decodeToken = decodeToken(access_token);
      //   console.log(decodeToken)

      //   localStorage.setItem('token', access_token);
      //   state.token = token;
      // }

      // if( user ){
      //     localStorage.setItem( 'user', user);
      //     state.user = user;
      // }

      // if( username ){
      //     localStorage.setItem( 'userName', username);
      //     state.userName = username;
      // }

      // if( id ){
      //     localStorage.setItem( 'userId', id);
      //     state.userId = id;
      // }

      //Not Required....
      // if( groupId ){
      //     localStorage.setItem( 'groupId', groupId);
      //     state.groupId = groupId;
      // }

      
    }, 
    
    refreshUser(state){
      state.groupId = localStorage.getItem('groupId');
      state.user = localStorage.getItem('user');
      state.userId = localStorage.getItem('userId');
      state.userName = localStorage.getItem('userName');
      state.token = localStorage.getItem('token');
      state.status = 'authenticated'
    },

    logoutUser(state){
      state.status = 'not-authenticating'
      state.token = null;
      state.user = null;
      state.userName = null;
      state.userId = null;
      state.groupId = null;

      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('userName')
      localStorage.removeItem('userId')
      localStorage.removeItem('groupId')
    }
  },
  actions: {

    async loginUser({commit}, user){

      try {
        const request = await axios.post(`${process.env.VUE_APP_BACK_URL}/auth/login`, user);
        commit('loginUser', request.data);
        return { ok : true }
      } catch (error) {
        console.log(error)
        return { ok : false }
      }      
    },

    checkAuthentication({commit}){
      const token = localStorage.getItem('token');
      
      //Si no existe el token, se hace logout
      if( !token ) {
        commit('logoutUser')
        return {ok: false, message: 'No hay token'}
      }else{
        try {
          commit('refreshUser');
          return {ok: true, message: 'Error Loading from localStorage'}
        } catch (error) {
          return {ok: false, message: 'Error Loading from localStorage'}
        }
      }

      
      
    },

    async loadCatregories({commit}){
      try {
        const res = await fetch(`${process.env.VUE_APP_BACK_URL}/category/getAll/1`);
        const dataDB = await res.json()
        const arrayTareas = []
        for (let id in dataDB){
          arrayTareas.push(dataDB[id])
        }
        commit('load', arrayTareas)

      } catch (error) {
        console.log(error)
      }
      
    },

    async createCategory({commit}, category){
      try {
        const res = await fetch(`${process.env.VUE_APP_BACK_URL}/category/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(category)
        })

        const dataDB = await (res.json())
        console.log(dataDB.cat)
        commit('set', dataDB.cat)
        return true;

      } catch (error) {
        console.log(error)
        return false;
      }

    },

    async createItem({commit}, item){
      try {
        const res = await fetch(`${process.env.VUE_APP_BACK_URL}/item/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(item)
        })

        const dataDB = await (res.json())
        console.log(dataDB.item)
        commit('set', dataDB.item)
        return true;

      } catch (error) {
        console.log(error)
        return false;
      }
    },

    async loadItem({commit}, payload){
      commit('setItem', payload)
    },

    async cleanItems({commit}){
      commit('cleanItems')
    },

    async setCategorySelected({commit}, data){
      commit('categorySelected', data);
    },

    async setFlagCategoryHome({commit}, value){
      commit('setFlagCategoryHome', value)
    }, 

    async setOneItem({commit}, item){
      commit('setOneitem', value)
    }
  },
  getters:{
    currentStatus(state){
      return state.status;
    },

    username(state){
      return state.userName || 'Default user'
    },

    userId(state){
      return state.userId
    },

    token(state){
      return state.token;
    }
  },
  modules: {
  }
})
