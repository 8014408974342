<template>
    <div>
        <HomeUsers/>
        <!-- <Accounts/> -->
    </div>
</template>

<script>
    import HomeUsers from '../components/HomeUsers.vue'
    // import Accounts from '../components/Account/Accounts.vue'

export default {
    components:{
        HomeUsers,
        // Accounts
    }    
}
</script>
