<template>
   <div class="card-container flex-1">
    <form @submit.prevent="handleSubmit">
      <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Name</h5>
        <InputText type="text" v-model.trim="account.name" />
      </div>

      <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Currency</h5>

        <Dropdown 
          v-model="account.currencyId"
          :options="currencies"
          optionLabel="name" 
          optionValue="id"  
          placeholder="Select One Currency"
        />
      </div>

      <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Description</h5>
        <InputText type="text" v-model.trim="account.description" />
      </div>

      <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Balance</h5>
        <InputNumber v-model="account.balance" mode="currency" currency="USD" locale="es-us" />
      </div>

      <div class="mt-3">
        <Divider/>
      </div>

      <div class="flex flex-row align-content-center justify-content-center mt-2">
        <Button 
            label="Cancel" 
            severity="danger" 
            class="mt-3 mr-2 w-4" 
            @click="emit('closeModal', true)"/>
          
        <Button 
            type="submit"  
            severity="success" 
            label="Save" 
            class="mt-3 ml-2 w-4" />
      </div>
    </form>
   </div>

</template>

<script setup>
import { ref, onMounted } from 'vue';
import useAuth from '@/composable/useAuth';
import { useToast } from 'primevue/usetoast';
import cmApi from '../../../api/cmApi';

const emit = defineEmits(['closeModal']);
const _useAuth = useAuth();
const toast = useToast();
const userId = ref(_useAuth.userId);
const currencies = ref([]);
const account = ref({
  name: '', 
  description: '', 
  balance: 0, 
  userId: '', 
  currencyId: 0, 
  active:true,
});



onMounted(async () => await getCurrencies())

const getCurrencies = async () => {
  const { data } = await cmApi.get(`/account/currencies`);
  currencies.value = [];
  currencies.value.push(...data);  
}

const handleSubmit = async () => {
  try{
    account.value.userId = userId.value;
    await cmApi.post(`/account/create`, account.value);
    toast.add({severity:'success', summary: 'Accounts', detail:'Account Register', life: 3000});
    emit('closeModal', true);
  }catch(err){
    toast.add({severity:'error', summary: 'Accounts', detail:'Account no Register', life: 3000});
  }
  
}

</script>

<style scoped>

</style>