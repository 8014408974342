<template>
    <Toast />
    <Dialog header="Register Payment" v-model:visible="showModal" modal class="w-3" :closeOnEscape="closeOnScape" :closable="closeOnScape">
        <div class="card-container flex-1">
            <form @submit.prevent="update(!v$.$invalid)">

                <div class="flex flex-column col-12">
                    <h5 class="mt-0 mb-1" :class="{'p-error':v$.account.$invalid && submitted}">Accounts <b>*</b></h5>
                    <Dropdown 
                        v-model="payment.account"
                        :options="accounts"
                        optionLabel="label"
                        placeholder="Select an Account"
                    />
                    <small v-if="v$.account.$invalid && submitted" class="p-error">
                        <b>You must be select an Account</b>
                    </small>
                </div>

                <div class="flex flex-column col-12">
                    <h5 class="mt-0 mb-1" >{{ `Amount (${payment.account.symbol})` }}</h5>
                    <InputNumber v-model="payment.amount" mode="currency" currency="USD" locale="en-US" />
                </div>

                <div class="flex flex-column col-12">
                    <h5 class="mt-0 mb-1" >Dolar Price</h5>        
                    <InputNumber v-model="payment.dolarPrice" mode="currency" currency="USD" locale="en-US" />
                </div>

                <div class="flex flex-column col-12">
                    <h5 class="mt-0 mb-1" >Reference</h5>        
                    <InputText type="text" v-model.trim="payment.reference" />
                </div>

                <div class="mt-3">
                    <Divider/>
                </div>

                <div class="flex flex-row align-content-center justify-content-center mt-2">
                    <Button 
                        label="Cancel" 
                        severity="danger" 
                        class="mt-3 mr-2 w-4" 
                        @click="showModal= false"/>
                    
                    <Button 
                        type="submit"  
                        severity="success" 
                        label="Save" 
                        class="mt-3 ml-2 w-4" />
                </div>
            </form>
        </div>
    </Dialog>

    <div>
        <div class="card">
             <div class="table-header">
                <Calendar class="calendar" v-model="dateSelected" view="month" dateFormat='MM yy' :showIcon="true" v-on:date-select="getItems" />
                <!-- <Button label="Add Item" icon="pi pi-plus" v-on:click="createItem()" class="btn-add" /> -->
            </div>

            <DataTable 
                v-model:filters="filters" 
                :value="items" 
                responsiveLayout="scroll" 
                :rows="10" 
                :rowsPerPageOptions="[5, 10, 20, 50]"
                :paginator="true"  
                filterDisplay="menu" 
                globalFilterFields="['payed', 'Item.User.firstName']"
                class="p-datatable-sm"
                :update:filters="totalBudgetC()">

                <Column field="Item.name" header="Item" :sortable="true" >
                    <template #body="item">
                        <span>{{item.data.Item.name}}</span>
                    </template>
                </Column>

                <Column field="Category.name" header="Category" :sortable="true" />
                
                <Column field="Item.budget" header="Budget" :sortable="true">
                    <template #body="item">
                        {{convert(item.data.budget)}}
                    </template>
                </Column>

                <Column field="amount" header="Payed" :sortable="true">
                <template #body="period">
                        {{convert(period.data.amount)}}
                    </template>
                </Column>

                <Column field="difference" header="Difference">
                    <template #body="item">
                           <!-- {{ period.data.difference }} % -->
                           <span v-if="item.data.difference < 0" class="difference color-active">{{ item.data.difference }} % </span>
                           <span v-if="item.data.difference > 0" class="difference color-inactive" >{{ item.data.difference }} %</span>
                           <span v-if="item.data.difference == 0" class="difference color-equal" >{{ item.data.difference }} %</span>
                    </template>
                </Column>
                

                <!-- <Column field="dolarPrice" header="Precio Dolar" :sortable="true">
                <template #body="period">
                        {{ period.data.payed ? applyCurrencyFormat(period.data.dolarPrice) : "N/A"}}
                    </template>
                </Column>

                <Column field="dolarAmount" header="Monto Real Dolar" :sortable="true">
                <template #body="period">
                        {{ period.data.payed ? applyCurrencyFormat(period.data.dolarAmount) : "N/A"}}
                    </template>
                </Column> -->

                <Column field="payed" filterField="payed"  :showFilterMatchModes="false" :filterMenuStyle="{'width':'14rem'}" header="Registered" :sortable="true">
                    <template #body="item">
                        <span v-if="item.data.payed" class="badge status-active">YES</span>
                        <span v-else class="badge status-inactive">NO</span>
                    </template> 

                    <template #filter="{filterModel, filterCallback}">
                        <div class="mb-3 font-bold">Is Registered?</div>
                        <MultiSelect 
                            v-model="filterModel.value" 
                            @change="filterCallback()" 
                            :options="payedStatus" 
                            optionLabel="name" 
                            optionValue="value"
                            placeholder="any"
                            class="p-column-filter">
                        </MultiSelect>
                    </template>
                </Column>

                <!-- <Column field="diferency" header="Diferencia" :sortable="true">
                    <template #body="item">
                        <Button :icon=getDiference(item) :class=getDiference02(item) style="width: 0.5em height:0.5em"/>
                    </template>
                </Column> -->

                <Column header="Payment">
                    <template #body="item">
                        <Button 
                            icon="pi pi-credit-card" 
                            severity="success" rounded outlined
                            aria-label="Search"
                            @click="() => itemSeleted(item.data)"/>
                    </template>
                </Column>

                <!-- <ColumnGroup type="footer">
                    <Row>
                        <Column :colspan="2" />
                        <Column footer="Total:" footerStyle="text-align:center" />
                        <Column :footer="totalBudget" />
                        <Column :footer="calculateTotalAmount" />
                        <Column  />
                        <Column :footer="calculateTotalAmountDolar" />
                        <Column :colspan="2" />
                    </Row>
                </ColumnGroup> -->

            </DataTable>
        </div>
    </div>
</template>

<script setup> 
import { ref, onMounted, computed } from 'vue';
const cm = require('../../../common/common');
import {FilterMatchMode} from 'primevue/api';
import useAuth from '@/composable/useAuth'
import cmApi from '../../../api/cmApi';
import { useToast } from 'primevue/usetoast';
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

const dateSelected = ref(new Date());
const filters = ref({'payed' : {value: [], matchMode: FilterMatchMode.IN, name:'payed'}});
const payedStatus = ref([{ name: 'YES', value: true }, { name: 'NO', value: false }]);
const items = ref([]);
const accounts = ref([]);
const period = ref();
const serviceName = ref('');
const showModal = ref(false);
const showInfoModal = ref(false);
const dolarPrice = ref(0);
const totalBudget = ref(0);
const _useAuth = useAuth();
const userId = ref(_useAuth.userId)
const toast = useToast();
const closeOnScape = ref(false)
const accountSelected = ref({label: '', value:'', symbol:'ARS', currencyId: 0});
const submitted = ref(false);

const payment = ref({
    account: accountSelected.value,
    amount: 0,
    reference: '',
    dolarPrice: '0'
})

const rules = {
    account: {
        value: {required}
    }
}

const v$ = useVuelidate(rules, payment);
 
onMounted(async () => {
    await getItems();
    await getDolarPrice();
    await getAccounts();
})

const getDolarPrice = async () => {
    const {data} = await cmApi.get(`/common/dolarBlue`);
    dolarPrice.value = data.venta;
}

const getAccounts = async () => {
    const {data} = await cmApi.get(`/account/getAll/${userId.value}`);
    accounts.value = [];
    data.map(acc => accounts.value.push({label: `${acc.currency.symbol} - ${acc.name}`, value:acc.id, symbol: acc.currency.symbol, currencyId: acc.currency.id}));
}

const getItems = async () => {
    const { data } = await cmApi.get(`/budget/getAllByUser/${userId.value}/${dateSelected.value.getMonth() +1 }/${dateSelected.value.getFullYear()}`);

    items.value = [];
    items.value.push(...data.items);

    totalBudgetC();
}

const totalBudgetC = () => {
    let data = items.value;
    const payedFilter = filters.value['payed'].value;

    if(payedFilter && payedFilter.length > 0){
        data = data.filter(i => payedFilter.includes(i.payed));
    }

    totalBudget.value = data.reduce((acc, cur) => acc + cur.budget, 0)
} 

const clear = () => {
    payment.value = {
        account: accountSelected.value,
        amount: 0,
        reference: '',
        dolarPrice: '0'
    }
}

const convert = (amount, currencyCode = "ARS") => {
    return cm.applyCurrencyFormat(amount, currencyCode)
}

const applyCurrencyFormat = (amount) => {
    return cm.applyCurrencyFormat(amount ? amount : 0, "USD")
}

const itemSeleted = (item) => {
    clear();
    payment.value.dolarPrice = dolarPrice.value;
    period.value = item;
    serviceName.value = period.value.Category.name;
    showModal.value = true;
}

const update = async (isFormValid) => {
    submitted.value = true;
        if(!isFormValid) return;

    const _period = {
        itemId: period.value.Item.id,
        categoryId: period.value.Category.id,
        periodId: period.value.id,
        amount: payment.value.amount,
        dolarPrice: dolarPrice.value,
        ref: payment.value.reference,
        year: dateSelected.value.getFullYear(),
        month: dateSelected.value.getMonth() + 1,
        budget: period.value.Item.budget,
        currencyId: payment.value.account.currencyId,
        accountId: payment.value.account.value,
        type: 'EXPENSE'
    }

    const { data } = await cmApi.post(`/budget`, _period);
    showModal.value = false

    if(data){
        await getItems();
        toast.add({severity:'success', summary: 'Pago Registrado', detail:'Pago Registrado', life: 3000});
    }else{
        toast.add({severity:'error', summary: 'Pago no Registrado', detail:'Pago no Registrado', life: 3000});
    }
}

const getDiference = (item) => {
    const budget = item.data.Item.budget;
    const payed = item.data.amount;

    if(payed > budget){
        return 'pi pi-angle-double-up'
    }else if (payed < budget) {
        return 'pi pi-angle-double-down'
    } else {
        return 'pi pi-check'
    }
}

const getDiference02 = (item) => {
    const budget = item.data.Item.budget;
    const payed = item.data.amount;
    
    if(payed > budget){
        return 'p-button-sm p-button-rounded p-button-danger p-button-outlined'
    }else if (payed < budget) {
        return 'p-button-sm p-button-rounded p-button-success p-button-outlined'
    } else {
        return 'p-button-sm p-button-rounded p-button-outlined'
    }
}

const getMontoReal = (item) => {
    return item.Periods[0].amount;
}

const calculateTotalAmount = computed(() => {
    let total = 0;
    for(let item of items.value){
        total += item.amount;
    }

    return convert(total);
});


const calculateTotalBudget = computed(() => {
    let total = 0;
    for(let item of items.value){
        total += item.budget;
    }

    return convert(total);
});

const calculateTotalBudgetDolar = computed(() => {
    const total = calculateTotalBudget();
    return applyCurrencyFormat(total/dolarPrice.value);

});

const calculateTotalAmountDolar = computed(() => {
    let total = 0;
    for(let item of items.value){
        total += item.payed ?  Number(item.dolarAmount) : 0
    }

    return applyCurrencyFormat(total);
});

</script>

<style scoped>

    .p-button {
        background: #256029;
    }

    .table-header {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
    }

    .btn {
        margin-top: 1em;
    }

    .dropdown{
        margin-right: 2em;
        width: 8em;
    }

    .btn-add {
        margin-right: 1em;
    }

    .icon-item-name {
        float: left;
        margin-right: 1em;
    }

    .calendar {
        margin-right: 1em;
    }

</style>