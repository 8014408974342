import { createApp } from 'vue'
import App from './views/App.vue'
import router from './router'
import store from './store'
import './theme/theme.scss'
import './style.css'
// import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Dialog from 'primevue/dialog';
import Row from 'primevue/row';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import Card from 'primevue/card';
import InputSwitch from 'primevue/inputswitch';
import MultiSelect from 'primevue/multiselect';
import TabMenu from 'primevue/tabmenu';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import MegaMenu from 'primevue/megamenu';
import Avatar from 'primevue/avatar';
import Tooltip from 'primevue/tooltip';


createApp(App)
.use(store)
.use(router)
.use(PrimeVue)
.use(ToastService)
.component('DataTable', DataTable)
.component('Avatar', Avatar)
.component('Column', Column)
.component('Row', Row)
.component('ColumnGroup', ColumnGroup)
.component('Dialog', Dialog)
.component('Toast', Toast)
.component('InputText', InputText)
.component('InputNumber', InputNumber)
.component('Dropdown', Dropdown)
.component('Button', Button)
.component('TabMenu', TabMenu)
.component('Sidebar', Sidebar)
.component('Card', Card)
.component('InputSwitch', InputSwitch)
.component('MultiSelect', MultiSelect)
.component('Calendar', Calendar)
.component('Divider', Divider)
.component('MegaMenu', MegaMenu)
.directive('tooltip', Tooltip)
.mount('#app')
