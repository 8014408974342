<template>
    <div class="p-mt-4">
        <div class="p-grid">
            <div class="p-col p-d-flex p-jc-center" fluid v-for="u in users" :key=u.id>
                <Card style="width: 20rem; margin-bottom: 2em">
                    <template #header>
                        <i class="pi pi-user" style="fontSize: 2rem"></i>
                    </template>
                    <template #title>
                        {{u.name}}
                        <hr>
                    </template>
                    <template #content>
                        <p>Presupuesto: {{priceFormat(u.budget)}}</p>
                        <p>Gastado: {{priceFormat(u.total)}}</p>
                        <p v-if="u.disponible > 0">Disponible: <span class="avalible">{{priceFormat(u.disponible)}}</span></p>
                        <p v-else>Excedido: <span class="nonavalible">{{priceFormat(u.disponible * -1)}}</span></p>
                        <p>Servicios: {{u.itemPayed}} de {{u.items}} ( {{ percentage(u.items, u.itemPayed)}} % )</p> 
                    </template>
                </Card>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import axios from 'axios'
    import cm from '../../../common/common'

export default {
    name: 'HomeUsers',

    created(){
        this.getMonthYear();
        this.getUsersByGroup()
    },

    data(){
        return{
            users: [],
            month: '',
            year: ''
        }
    },

    methods: {
        async getUsersByGroup(){
            const req = await axios.get(`${process.env.VUE_APP_BACK_URL}/user/getInfo/${this.groupId}/${this.month}/${this.year}`);
            this.users = req.data;
        },

        percentage(items, payed){
            return parseFloat((payed / items) * 100).toFixed(0);
        },

        priceFormat(amount){
            return cm.convertToAmount(amount);
        },

        getMonthYear(){
            const actual = new Date();
            this.month = (0 + (actual.getMonth() + 1).toString()).slice(-2);
            this.year = actual.getFullYear().toString();
        }
    },

    computed:{
        ...mapState(['groupId'])
    }

}
</script>

<style scoped>
    .avalible {
        color: green;
        font-weight: bold;
    }

    .nonavalible{
        color: red;
        font-weight: bold;
    }
</style>