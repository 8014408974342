<template>
 <div class="card-container flex-1">
    <form @submit.prevent="handleSubmit">
     
        <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Name</h5>
        <InputText type="text" v-model.trim="category.name" />
      </div>


      <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Description</h5>
        <InputText type="text" v-model.trim="category.description" />
      </div>


      <div class="mt-3">
        <Divider/>
      </div>

      <div class="flex flex-row align-content-center justify-content-center mt-2">
        <Button 
            label="Cancel" 
            severity="danger" 
            class="mt-3 mr-2 w-4" 
            @click="emit('closeModal', true)"/>
          
        <Button 
            type="submit"  
            severity="success" 
            label="Save" 
            class="mt-3 ml-2 w-4" />
      </div>
    </form>
   </div>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from 'vue';
import { useToast } from 'primevue/usetoast';
import cmApi from '../../../api/cmApi';
const cm = require('../../../common/common');

const toast = useToast();
const props = defineProps({
    userId: '',
});

const emit = defineEmits(['closeModal']);

const category = ref({
    name: '',
    description: '',
    userId: props.userId,
})

const handleSubmit = async () => {
    try {
        await cmApi.post(`/Categories`, category.value);
        toast.add({severity:'success', summary: 'Category', detail:'Category Register', life: 3000});
        emit('closeModal', true);
    } catch (error) {
        toast.add({severity:'error', summary: 'Category', detail:'Category no Register', life: 3000});
    }
}


</script>

<style scoped>

</style>