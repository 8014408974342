exports.convertToAmount = (amount) =>{
    const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
    });

    return formatter.format(amount);
}

exports.applyCurrencyFormat = (amount, currency) => {
    const formatter = new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: currency,
    });

    return formatter.format(amount);
}

exports.formatLocalDate = (dateString) => {
    if(!dateString) return '-';
    const date = new Date(dateString);
    return `${calculateZeroDate(date.getDate())}/${calculateZeroDate(date.getMonth()+1)}/${date.getFullYear()}`
    //return date.toLocaleString().split(",")[0];
}

function calculateZeroDate(date) {
    if(date < 10) return `0${date}`;
    return date;
}