import { createRouter, createWebHistory } from 'vue-router'
import Login from '../modules/auth/views/Login.vue'
import Category from '../modules/category/view/Category.vue'
import Home from '../modules/dashboard/views/Home.vue'
import Items from '../modules/items/views/Items.vue'
import Budget from '../modules/budget/views/Budget.vue'
import Account from '../modules/account/views/Account.vue'
import AppView from '../views/App.vue'
import isAuthenticatedGuard from "./auth-guard";

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      disableIfLoggedIn: true
    }
  },


  {
    path: '/',
      beforeEnter: [isAuthenticatedGuard],
      children: [
        {
          path: '/',
          name: 'Home',
          component: Home
        },
      
        {
          path: '/category',
          name: 'Category',
          component: Category
      
        },
      
        {
          path: '/items',
          name: 'Items',
          component: Items
        },
        {
          path: '/budget',
          name: 'Budget',
          component: Budget
        },
        {
          path: '/account',
          name: 'Account',
          component: Account
        }
      ]
  }

  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
