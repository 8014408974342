import { computed } from "vue";
import { useStore } from "vuex";

const useAuth = () => {
    const store = useStore();

    const doLogin = async( user ) => {
        const response = await store.dispatch('loginUser', user);
        return response;
    } 

    const checkAutStatus = async () => {
        const response = await store.dispatch('checkAuthentication')
        return response
    }

    const logout = () => {
        store.commit('logoutUser')
    }

    return {
        doLogin,
        checkAutStatus,
        logout,
        authStatus: computed(() => store.getters['currentStatus']),
        username: computed(() => store.getters['username']),
        userId: computed(() => store.getters['userId']),
        token : computed(() => store.getters['token']),
    }
}

export default useAuth;