<template>
    <Toast />

    <div class="container main"  >
        <Card style="width: 30rem;">
            <template #header>
                <h2>Welcome to Control Manager</h2>
            </template>

            <template #content>
                <div class="card-container flex-1">
                    <form @submit.prevent="doLogin">
                        <div class="p-grid p-formgrid">
                            <div class="flex flex-column col-12">
                                <h5>Email</h5>        
                                <InputText type="email" v-model.trim="userForm.email" />
                            </div>

                            <div class="flex flex-column col-12">
                                <h5>Password</h5>        
                                <InputText type="password" v-model.trim="userForm.password" />
                            </div>

                            <div class="mt-3">
                                <Divider/>
                            </div>

                            <div class="flex flex-row align-content-center justify-content-center mt-2">
                                <Button type="submit" label="Login" class="p-mt-4" ></Button>
                            </div>

                        </div>
                    </form>
                </div>
            </template>

            <!-- <template #content>
                <div class="p-grid container">
                    <div class="p-col-12 p-fluid">
                        <form @submit.prevent="doLogin">
                            <div class="p-grid p-formgrid">
                    
                                <div class="p-col-12 ">
                                    <h5>Email</h5>        
                                    <InputText type="email" v-model.trim="userForm.email" />
                                </div>

                                <div class="p-col-12 ">
                                    <h5>Password</h5>        
                                    <InputText type="password" v-model.trim="userForm.password" />
                                </div>
                            </div>

                            <div>
                                <Button type="submit" label="Login" class="p-mt-4 p-button-success" ></Button>
                            </div>
                        </form>
                    </div>
                </div>
            </template> -->
        </Card>
    </div>

</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useAuth from '../../../composable/useAuth';
import { useToast } from "primevue/usetoast";

    export default {

        setup(){
            const router = useRouter();
            const toast = useToast();
            const { doLogin } = useAuth();

            const userForm = ref ({
                email: '',
                password: ''
            })

            return {
                userForm,

                doLogin: async() => {
                    const response = await doLogin(userForm.value);
                    if(!response.ok) toast.add({severity:'error', summary: 'Login Fail', detail:'Please check your credentials.', life: 3000});
                    router.push({name: 'Home'})
                }
            }
        }
    }
</script>

<style scoped>
    .btnSize {
        width: 10em;
    }

    .logo{
        width: 50px;
        height: 50px;
    }

    .bordes{
        border: 1em;
        border-color: black;
    }

    .container{
        display: flex;
        justify-content: center;
        align-content: center;
    }

    .container.main{
        margin-top: 5em;
    }

</style>
