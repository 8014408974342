<template>
    <div class="header">
        <h2 class="p-ml-3">Control Manager</h2>

        <div class="headerInfo" v-if="authStatus == 'authenticated'">
            <h3 class="p-mr-4">{{ username }}</h3>
            <i class="pi pi-user p-mr-5" style="fontSize: 2rem" @click="onLogout"></i>    
        </div>
    </div>
</template>

<script>
import useAuth from '@/composable/useAuth'
import { useRouter } from 'vue-router';

export default {
    setup(){
        const router = useRouter();
        const { username, logout, authStatus, userId } = useAuth();

        return {
            username,
            authStatus,
            userId,

            onLogout: () => {
                router.push({name : 'Login'})
                logout()
            }
        }
    }
}
</script>

<style scoped>
    .header {
        display: flex;
        justify-content: space-between;
        color: #fff;
        background-color: var(--green-500);
    }

    .headerInfo{
        display: flex;
        align-items: center;
    }
</style>