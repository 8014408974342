<template>
    <Toast/>
    <Dialog header="Create Item" v-model:visible="showModal" modal class="w-3">
        <UpsertItem @closeModal="closeModal" :categories="categories" :userId="userId"/>
    </Dialog>

        <div class="card">
            <div class="flex align-content-between">
                <div class="flex flex-1 justify-content-center">
                    <h2>Items</h2> 
                </div>
                
                <div class="mt-3">
                    <Button 
                        label="Add Item" 
                        class="button"
                        raised 
                        @click="() => showModal=true" />
                </div>
            </div>

            <DataTable
            :value="items"
            responsiveLayout="scroll"
            :rows="10"
            :rowsPerPageOptions="[5, 10, 20, 50]"
            :paginator="true"
            editMode="row"
            dataKey="id"
            v-model:editingRows="editingRows" 
            @row-edit-save="onRowEditSave"
            class="p-datatable-sm"
        >

        <Column field="name" header="Name" :sortable="true">
            <template #editor="{data}">
                <InputText type="text" v-model.trim="data[`name`]" />
            </template>
        </Column>
        
        
        <Column field="description" header="Description" :sortable="true">
            <template #editor="{data}">
                <InputText type="text" v-model.trim="data[`description`]" />
            </template>
        </Column>

        <Column field="createdAt" header="Created At" :sortable="true">
            <template #body="{data}">
                {{formatDate(data.createdAt)}}
            </template>
        </Column>

        <Column field="Category" header="Category" :sortable="true">
            <template #body="{data}">
                {{data.Category.name}}
            </template>

            <template #editor="{data}">
                <Dropdown v-model="data[`Category`]" :options="categories" optionLabel="name" placeholder="Select a Categorie">
                </Dropdown>
            </template>
        </Column>
        
        <Column field="budget" header="Budget" :sortable="true">
            <template #editor="{data}">
                <InputNumber v-model="data[`budget`]" mode="currency" currency="ARS" locale="es-AR" />
            </template>

            <template #body="{data}">
                {{formatToCurrency(data.budget)}}
            </template>
        </Column>

        <Column field="active" header="Status" :sortable="true">
            <template #editor="{data}">
                <Dropdown v-model="data[`active`]" :options="statuses" optionLabel="label" optionValue="value" placeholder="Select a Status">
                    <template #option="slotProps">
                        <span :class="`badge status-${slotProps.option.label.toLowerCase()}`">{{slotProps.option.label}}</span>
                    </template>
                </Dropdown>
            </template>

            <template #body="{data}">
                <span v-if="data.active" class="badge status-active">Active</span>
                <span v-else class="badge status-inactive">Inactive</span>
            </template>
        </Column>

        <Column header="Options" :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>

        </DataTable>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import cmApi from '../../../api/cmApi';
const cm = require('../../../common/common');
import useAuth from '@/composable/useAuth'
import { useToast } from 'primevue/usetoast';
import UpsertItem from './UpsertItem.vue';

const showModal = ref(false);
const toast = useToast();
const _useAuth = useAuth();
const userId = ref(_useAuth.userId)
const items = ref([]);
const categories = ref([]);
const editingRows = ref([]);
const statuses = ref([
    {label : 'Active', value: true},
    {label : 'Inactive', value: false}
]);

onMounted(async () => {
    await getItems();
    await getCategories();
})

const getItems = async () => {
    try{
        const {data} = await cmApi.get(`/items/getByUser/${userId.value}`);
        items.value = [];
        items.value.push(...data);
    }catch(err){
        toast.add({severity:'error', summary: 'Get Items', detail:'Items cannot be getting from server', life: 3000});
    }
}

const getCategories = async () => {
    try{
        const {data} = await cmApi.get(`/Categories/getAllByUser/${userId.value}`);
        categories.value = [];
        categories.value.push(...data);
    }catch(err){
        toast.add({severity:'error', summary: 'Categories', detail:'Categories cannot be getting from server', life: 3000});
    }
}

const onRowEditSave = async (event) => {
    try{
        const {newData:body, index, data:actual} = event;
        await cmApi.put(`/Items`, body);
        toast.add({severity:'success', summary: 'Update Item', detail:`${event.data.name} updated`, life: 3000});
        await getItems();
    }catch(err){
        toast.add({severity:'error', summary: 'Update Item', detail:`${event.data.name} can not be update`, life: 3000});
    }
}

const closeModal = async () => {
  await getItems();
  showModal.value = false;
}

const formatToCurrency = (amount) => {
    return cm.convertToAmount(amount);
}

const formatDate = (date) => {
    return cm.formatLocalDate(date);
}

</script>

<style scoped>

</style>