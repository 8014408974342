<template>
    <Toast/>
    <Dialog header="Create Category" v-model:visible="showModal" modal class="w-3">
        <UpsertCategory @closeModal="closeModal" :userId="userId"/>
    </Dialog>
    
   <div class="card">
      <div class="flex align-content-between">
          <div class="flex flex-1 justify-content-center">
              <h2>Categories</h2> 
          </div>
          
          <div class="mt-3">
              <Button 
                  label="Add Category" 
                  raised 
                  @click="() => showModal=true" />
          </div>
      </div>

      <DataTable
        :value="categories"
        responsiveLayout="scroll"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        :paginator="true"
        editMode="row"
        dataKey="id"
        v-model:editingRows="editingRows" 
        @row-edit-save="onRowEditSave"
        class="p-datatable-sm">

      <Column field="name" header="Name" :sortable="true">
          <template #editor="{data}">
              <InputText type="text" v-model.trim="data[`name`]" />
          </template>
      </Column>
      
      
      <Column field="description" header="Description" :sortable="true">
          <template #editor="{data}">
              <InputText type="text" v-model.trim="data[`description`]" />
          </template>
      </Column>

      <Column field="createdAt" header="Created At" :sortable="true">
          <template #body="{data}">
              {{formatDate(data.createdAt)}}
          </template>
      </Column>



      <Column header="Options" :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>

      </DataTable>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import cmApi from '../../../api/cmApi';
const cm = require('../../../common/common');
import useAuth from '@/composable/useAuth'
import { useToast } from 'primevue/usetoast';
import UpsertCategory from './UpsertCategory.vue';

const showModal = ref(false);
const toast = useToast();
const _useAuth = useAuth();
const userId = ref(_useAuth.userId)
const categories = ref([]);
const editingRows = ref([]);

onMounted(async () => {
    await getCategories();
})

const getCategories = async () => {
  try{
      const {data} = await cmApi.get(`/Categories/getAllByUser/${userId.value}`);
      categories.value = [];
      categories.value.push(...data);
  }catch(err){
      toast.add({severity:'error', summary: 'Categories', detail:'Categories cannot be getting from server', life: 3000});
  }
}

const onRowEditSave = async (event) => {
    try{
        const {newData:body} = event;
        await cmApi.put(`/Categories`, body);
        toast.add({severity:'success', summary: 'Update Item', detail:`${event.data.name} updated`, life: 3000});
        await getCategories();
    }catch(err){
        toast.add({severity:'error', summary: 'Update Item', detail:`${event.data.name} can not be update`, life: 3000});
    }
}

const closeModal = async () => {
  await getCategories();
  showModal.value = false;
}

const formatDate = (date) => {
  return cm.formatLocalDate(date);
}

</script>

<style scoped>

</style>