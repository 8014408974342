<template>
    <div class="card-container flex-1">
    <form @submit.prevent="handleSubmit">
     
        <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Name</h5>
        <InputText type="text" v-model.trim="item.name" />
      </div>

      <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Category</h5>

        <Dropdown 
          v-model="item.categoryId"
          :options="categories"
          optionLabel="name" 
          optionValue="id"  
          placeholder="Select One Category"
        />
      </div>

      <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Description</h5>
        <InputText type="text" v-model.trim="item.description" />
      </div>

      <div class="flex flex-column col-12">
        <h5 class="mt-0 mb-1">Budget</h5>
        <InputNumber v-model="item.budget" mode="currency" currency="USD" locale="es-us" />
      </div>

      <div class="mt-3">
        <Divider/>
      </div>

      <div class="flex flex-row align-content-center justify-content-center mt-2">
        <Button 
            label="Cancel" 
            severity="danger" 
            class="mt-3 mr-2 w-4" 
            @click="emit('closeModal', true)"/>
          
        <Button 
            type="submit"  
            severity="success" 
            label="Save" 
            class="mt-3 ml-2 w-4" />
      </div>
    </form>
   </div>
</template>

<script setup>
import { ref, onMounted, computed, defineProps } from 'vue';
import { useToast } from 'primevue/usetoast';
import cmApi from '../../../api/cmApi';
const cm = require('../../../common/common');

const toast = useToast();
const props = defineProps({
    categories: [],
    userId: '',
});

const emit = defineEmits(['closeModal']);

const item = ref({
    name: '',
    description: '',
    budget: '',
    categoryId: '',
    userId: props.userId,
})

const handleSubmit = async () => {
    try {
        await cmApi.post(`/Items`, item.value);
        toast.add({severity:'success', summary: 'Items', detail:'Item Register', life: 3000});
        emit('closeModal', true);
    } catch (error) {
        toast.add({severity:'error', summary: 'Item', detail:'Item no Register', life: 3000});
    }
}

</script>

<style scoped>

</style>