<template>
	<TabMenu :model="items"></TabMenu>
</template>

<script>
import { ref } from 'vue';

export default {
	setup(){

		const items = ref([
			{
				label: 'Home',
				icon: 'pi pi-home',
				to: '/'
			},
			{
				label: 'Cuentas',
				icon: 'pi pi-wallet',
				to: '/account'
			},
			{
				label: 'Categorias',
				icon: 'pi pi-bookmark',
				to: '/category'
			},

			{
				label: 'Items',
				icon: 'pi pi-ticket',
				to: '/items'
			},

			{
				label: 'Presupuesto',
				icon: 'pi pi-money-bill',
				to: '/Budget'
			}
		]);

		return {items}
	}
}

</script>

<style>

</style>