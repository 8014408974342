<template>
  <footer class="container">
    <small style="font-weight: bolder">App Version: {{ appVersion }} - Api Version: {{ apiVersion }}</small>
  </footer>
</template>

<script setup>
import packageInfo from '../../package.json';
import { ref, onMounted } from 'vue';
import cmApi from '../api/cmApi';

const appVersion = ref(packageInfo.version);
const apiVersion = ref("1.0.0");

onMounted(async () => await getApiVersion());

const getApiVersion = async () => {
  const { data } = await cmApi.get("/info");
  apiVersion.value = data.apiVersion;
}

</script>

<style scoped>

.container{
    margin-top: 10em;
    width: 100%;
    margin-bottom: 1em;
    position: fixed;
    bottom: 0;
}

</style>