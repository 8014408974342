
<template>
    <div class="container">
        <MegaMenu :model="items" class="flex flex-1" :activeIndex="activeIndex">
            <template #start>
                <div class="flex flex-row align-items-center">
                    <p class="ml-2 mr-2" style="color:white" >Control Manager</p>
                    <p class="ml-2 mr-2" style="color:white" >|</p>
                </div>
            </template>

            <template #end>
                <div class="flex flex-row align-items-center">
                    <p class="ml-2 mr-2" style="color:white" >{{name}}</p>
                  <small>|</small>

                  <Avatar
                      :label="initialName()"
                      shape="circle"
                      class="avatar"
                      :style="{ 'background-color': avatarColor }"
                      style="color: white;"
                  />

                  
                  <Button 
                      style="height: 32px; width: 32px;  color: white;"
                      text
                      icon="pi pi-sign-out" 
                      class="ml-2"
                      aria-label="LogOut" 
                      v-tooltip.bottom="'Log Out'"
                      @click="Applylogout()"
                    />
                    
                    
                </div>
            </template>

        </MegaMenu>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useRouter } from "vue-router";
import useAuth from '@/composable/useAuth'
// import logo from '../assets/logoVertical.png';
// import { useAuthStore } from "../modules/auth/stores/auth";

// const authStore = useAuthStore();
const router = useRouter();
const activeIndex = ref(0);

const { username, logout, authStatus, userId } = useAuth();
const name = ref(username);
// const role = ref(authStore.getUser().rol);
// const position = ref(authStore.getUser().position)

const items = ref([
    { label: 'Dashboard', to: '/', role: ['USER', 'ADMIN', 'MANAGER'], command: () => (activeIndex.value = 0)},
    { label: 'Accounts', to: '/account', role: ['ADMIN'], command: () => (activeIndex.value = 1)},
    { label: 'Categories' , to:'/category', role: ['ADMIN'], command: () => (activeIndex.value = 2)},
    { label: 'Items', to: '/items', role: ['ADMIN', 'MANAGER'], command: () => (activeIndex.value = 3)},
    { label: 'Budget', to: '/Budget', role: ['ADMIN', 'MANAGER'], command: () => (activeIndex.value = 4)}
])

// const filteredItems = () => {
//     const role = authStore.getUser().rol;
//     return items.value.filter(i => i.role.includes(role))
// }

const Applylogout = () => {
    router.push({name : 'Login'})
    logout()
};

const initialName = () => {
    if(name.value){
        const sName = name.value.split(` `);
        return `${sName[0].charAt(0)}${sName[1].charAt(0)}`;
    }
  
    return 'CM'
};

// const showUserManagement = () => {
//     const show = ["ADMIN", "MANAGER"].includes(role.value)
//     return show;
// }

// const goToUserManagement = () => {
//   router.push({ name: "User" });
// };

const avatarColor = computed(() => {
    return 'var(--cm-primary)'
    //   if (role.value === 'ADMIN') {
    //     return '#FFD700'
    //   } else if (role.value === 'MANAGER') {
    //     return '#C0C0C0'
    //   } else {
    //     return '#CD7F32'
    //   }
})

</script>

<style scoped>
small{
  color: #fff;
  margin-right: 1em;
}


</style>

